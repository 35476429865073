import { useAuth0 } from '@auth0/auth0-react'
import { useTemplatesLazyQuery } from '@generated/graphql'
import { currentNetworkSelector } from '@store/slices/networks/selectors'
import { setTemplates, setTemplatesLoading } from '@store/slices/templates'
import { templatesSelector } from '@store/slices/templates/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { Modal } from 'antd'
import { useEffect } from 'react'

export function useFetchTemplates() {
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const currentNetwork = useAppSelector(currentNetworkSelector)
  const networkId = currentNetwork?.id
  const currentTemplates = useAppSelector(templatesSelector)
  const sitesLoading = useAppSelector(state => state.sites.loading)

  const [queryTemplates] = useTemplatesLazyQuery({
    onCompleted: ({ templates }) => {
      if (JSON.stringify(templates) !== JSON.stringify(currentTemplates)) {
        dispatch(setTemplates(templates))
      } else {
        dispatch(setTemplatesLoading(false))
      }
    },
    onError: error => {
      Modal.error({
        title: 'Templates failed to load',
        content: 'Please reload page',
      })
      console.error('Unable to load templates', error)
    },
  })

  useEffect(() => {
    if (!networkId || !isAuthenticated || sitesLoading) {
      return
    }

    queryTemplates({
      variables: {
        where: {
          networkId: {
            equals: networkId,
          },
        },
      },
    })
  }, [networkId, sitesLoading, isAuthenticated, queryTemplates, dispatch])
}
