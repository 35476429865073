import { useAuth0 } from '@auth0/auth0-react'
import { useNetworkContextSlimNetworksLazyQuery } from '@generated/graphql'
import { defaultLanguageIdSelector } from '@store/slices/languages/selectors'
import { setNetworks, setNetworksLoading } from '@store/slices/networks'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useCallback, useEffect } from 'react'

export function useFetchNetworks(refetchOnly: boolean = false) {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const defaultLanguageId = useAppSelector(defaultLanguageIdSelector)

  const [queryNetworks] = useNetworkContextSlimNetworksLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ networks }) => {
      dispatch(setNetworks(networks))
    },
    onError: error => {
      console.error('Unable to load networks', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || !defaultLanguageId || refetchOnly) {
      return
    }
    dispatch(setNetworksLoading(true))
    queryNetworks({
      variables: { defaultLanguageId },
    })
  }, [isAuthenticated, queryNetworks, defaultLanguageId, refetchOnly, dispatch])

  const fetchNetworks = useCallback(() => {
    dispatch(setNetworksLoading(true))
    queryNetworks({
      variables: { defaultLanguageId },
    })
  }, [dispatch, queryNetworks, defaultLanguageId])

  if (refetchOnly) {
    return fetchNetworks
  }
}
