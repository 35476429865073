import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TranslationState } from './types'

const initialState: TranslationState = {
  isTranslation: false,
  translationFields: [],
}

const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setIsTranslation(state, action: PayloadAction<boolean>) {
      state.isTranslation = action.payload
    },
    setIsTranslationSafe(state) {
      if (state.translationFields.length && !state.isTranslation) {
        state.isTranslation = true
      } else if (!state.translationFields.length && state.isTranslation) {
        state.isTranslation = false
      }
    },
    setTranslationFields(state, action: PayloadAction<string[]>) {
      state.translationFields = action.payload
    },
    resetTranslationFields(state) {
      state.translationFields = initialState.translationFields
    },
  },
})

export default translationSlice.reducer

export const {
  setIsTranslation,
  setIsTranslationSafe,
  setTranslationFields,
  resetTranslationFields,
} = translationSlice.actions
