import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const networksList = (state: RootState) => state.networks.list
const currentNetworkId = (state: RootState) => state.networks.currentNetworkId
const networksSelectorConfig = (state: RootState) =>
  state.networks.selectorConfig

export const networksListSelector = createSelector(
  [networksList],
  networksList => networksList,
)

export const networksSelectorConfigSelector = createSelector(
  [networksSelectorConfig],
  networksSelectorConfig => networksSelectorConfig,
)

export const currentNetworkIdSelector = createSelector(
  [currentNetworkId],
  currentNetworkId => currentNetworkId,
)

export const currentNetworkSelector = createSelector(
  [networksList, currentNetworkId],
  (networksList, currentNetworkId) =>
    networksList.find(network => network.id === currentNetworkId),
)

export const networksLoadingSelector = createSelector(
  [networksList, (state: RootState) => state.networks.loading],
  (networksList, loading) => !networksList?.length && loading,
)
